import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';

import knownLogosImages from '../../constants/knownLogosImages';

import styles from './KnownLogos.module.scss';

const KnownLogos = () => {
  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);
  return (
    <div className={styles.knownLogos}>
      <div className={styles.logosContainer} id="known-logos">
        {knownLogosImages.map((item, index) =>
          item.link ? (
            <a
              className={styles.logo}
              data-aos="fade"
              data-aos-delay={100 * Math.floor(Math.random() * 10)}
              data-aos-anchor={'#known-logos'}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <img
                src={item.image}
                style={{ width: item.width, maxHeight: 66 }}
                alt="Bekannt aus"
              />
            </a>
          ) : (
            <img
              className={styles.logo}
              data-aos="fade"
              data-aos-delay={100 * Math.floor(Math.random() * 10)}
              data-aos-anchor={'#known-logos'}
              src={item.image}
              style={{ width: item.width, maxHeight: 66 }}
              alt="Bekannt aus"
              key={index}
            />
          )
        )}
      </div>
    </div>
  );
};

export default KnownLogos;
