import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconPlus from '../../images/svg/IconPlus.svg';

import styles from './Faqs.module.scss';

const Faqs = ({ faqsItems, color }) => {
  return (
    <div className={cn(styles.faqs, styles['color_' + color])}>
      {faqsItems.map((item, index) => (
        <div className={styles.faqsItem} key={index}>
          <input type="checkbox" id={`question${index}`} />
          <label htmlFor={`question${index}`} className={styles.faqsQuestion}>
            <span>{item.question}</span>
            <IconPlus className={styles.plusIcon} />
          </label>
          <div
            className={styles.faqsAnswer}
            dangerouslySetInnerHTML={{ __html: `<p>${item.answer}</p>` }}
          />
        </div>
      ))}
    </div>
  );
};

Faqs.propTypes = {
  color: PropTypes.string,
  faqsItems: PropTypes.array
};

export default Faqs;
