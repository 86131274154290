import image11 from 'images/blue-section-items/firstRow/1-1.png';
import image12 from 'images/blue-section-items/firstRow/1-2.png';
import image13 from 'images/blue-section-items/firstRow/1-3.png';
import image14 from 'images/blue-section-items/firstRow/1-4.png';
import image15 from 'images/blue-section-items/firstRow/1-5.png';
import image16 from 'images/blue-section-items/firstRow/1-6.png';

import image21 from 'images/blue-section-items/secondRow/2-1.png';
import image22 from 'images/blue-section-items/secondRow/2-2.png';
import image23 from 'images/blue-section-items/secondRow/2-3.png';
import image24 from 'images/blue-section-items/secondRow/2-4.png';
import image25 from 'images/blue-section-items/secondRow/2-5.png';
import image26 from 'images/blue-section-items/secondRow/2-6.png';
import image27 from 'images/blue-section-items/secondRow/2-7.png';

const knownLogosImages = [
  {
    image: image11,
    link: '',
    width: '9em'
  },
  {
    image: image12,
    link: '',
    width: '18em'
  },
  {
    image: image13,
    link: '',
    width: '9em'
  },
  {
    image: image14,
    link: '//www.gedenkseiten.de/',
    width: '18em'
  },
  {
    image: image15,
    link: '',
    width: '9em'
  },
  {
    image: image16,
    link: '',
    width: '9em'
  },
  {
    image: image21,
    link: '//trauertattoo.de',
    width: '17em'
  },
  {
    image: image22,
    link: '',
    width: '6em'
  },
  {
    image: image23,
    link: '',
    width: '15em'
  },
  {
    image: image24,
    link: '',
    width: '5em'
  },
  {
    image: image25,
    link: '',
    width: '13em'
  },
  {
    image: image26,
    link: '',
    width: '10em'
  },
  {
    image: image27,
    link: '//www.convela.de/',
    width: '14em'
  }
];

export default knownLogosImages;
